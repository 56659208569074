import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { graphql, Link } from 'gatsby';
import { useLocation } from '@reach/router';

import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import { TagEventTracker } from 'components/TagEventTracker';

import { filterByTagId } from '../../utils/functions';
import { ArticleListProps } from './models';

import './ArticleList.scss';

const ArticleList: FC<{ data: ArticleListProps }> = ({
  data: {
    articleList: { urls, seo, id, tags, imageDesktop, imageMobile, headband },
    allArticle: { nodes: items },
    allArticleList: { nodes: articleLists },
    global: { read },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;
  const location = useLocation();

  const lists = articleLists.filter((list) => list.id !== id);
  const articles = filterByTagId(items, tags.id);

  return (
    <Layout headerTransparent {...{ headband }}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <TagEventTracker eventType="page_view" data={{ pageTemplate: location.pathname }}>
        <div className={`article-list article-list__${tags.name}`}>
          <div className="article-list__banner">
            <Image
              imageData={imageDesktop}
              alt={imageDesktop.altText}
              className="d-none d-md-block"
            />
            <Image
              imageData={imageMobile}
              alt={imageMobile.altText}
              className="d-block d-md-none"
            />
            <ul className="list-unstyled d-flex article-list__cats">
              {lists.map(({ id: lid, url, icon, title: name }) => (
                <li key={lid}>
                  <Link to={url}>
                    <Image imageData={icon} alt={name} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="article-list__listing mx-0 mx-md-4 pb-4">
            <div className="container-fluid d-none d-md-block">
              <div className="row">
                {articles.map(({ id: aid, url, thumbnail, title: name }) => (
                  <div className="col-6 col-md-3 text-center" key={aid}>
                    <div
                      className={`article-list__article mb-4 mx-auto article-list__article__${tags.name}`}
                    >
                      <Link to={url} className="d-block">
                        <Image imageData={thumbnail} alt={thumbnail.altText} />
                      </Link>
                      <h4 className="pt-3">{name}</h4>
                      <Link
                        className="p-2 border-0 d-flex align-items-center justify-content-center"
                        aria-label={name}
                        to={url}
                      >
                        {read}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="container-fluid d-block d-md-none">
              <div className="row">
                <div className="col">
                  <Carousel
                    showThumbs={false}
                    showIndicators
                    showArrows
                    showStatus={false}
                    infiniteLoop
                  >
                    {articles.map(({ id: aid, url, thumbnail, title: name }) => (
                      <div className="text-center pb-4" key={aid}>
                        <div
                          className={`article-list__article mb-4 mx-auto article-list__article__${tags.name}`}
                        >
                          <Link to={url} className="d-block">
                            <Image imageData={thumbnail} alt={thumbnail.altText} />
                          </Link>
                          <h4 className="pt-3">{name}</h4>
                          <Link
                            className="p-2 border-0 d-flex align-items-center justify-content-center"
                            aria-label={name}
                            to={url}
                          >
                            {read}
                          </Link>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TagEventTracker>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    articleList(url: { eq: $url }) {
      urls {
        lang
        href
      }
      headband {
        cta {
          url
          name
        }
        imageDesktop {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
        imageMobile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo {
        ...SEOStructureFragment
      }
      tags {
        id
        name
      }
      id
      imageDesktop {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
      }
      imageMobile {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
      }
    }
    global {
      read
    }
    allArticleList {
      nodes {
        id
        title
        url
        icon {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 100)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 100)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 100)
            }
          }
        }
      }
    }
    allArticle {
      nodes {
        id
        title
        url
        tags {
          id
          name
        }
        thumbnail {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 220)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 220)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 220)
            }
          }
        }
      }
    }
  }
`;

export default ArticleList;
